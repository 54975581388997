import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { ProductCard, ServiceCard, MainWrapper } from '../../shared';
import { IProduct, IService, products, services } from '../../../models';

import './ServicePage.scss';

export function ServicePage() {
  return (
    <MainWrapper currentPageName="Services" headerPageText="Products">
      <p className="mx-4 roboto-font-style">
        Currently available products include, but are not limited to. If you’re looking for something not listed, feel
        free to reach out to us!
      </p>
      <hr className="mx-3" />
      <Row className="p-0 m-0 ">
        {products.map((product: IProduct, index: number) => (
          <Col key={index} sm={12} md={6} xl={4} className="p-0 m-0">
            <ProductCard product={product} index={product.title}></ProductCard>
          </Col>
        ))}
      </Row>
      <h2 className="hard-font-style mx-3">Services</h2>
      <Row className="p-0 m-0 p-sm-1">
        {services.map((service: IService, index: number) => (
          <Col key={index} sm={12} md={6} xl={4} className="p-0 m-0">
            <ServiceCard service={service} index={service.title}></ServiceCard>
          </Col>
        ))}
      </Row>
    </MainWrapper>
  );
}

export default ServicePage;
