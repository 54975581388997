import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { FiArrowRight } from 'react-icons/fi';

import './GetInTouchBtn.scss';

interface GetInTouchBtnInterfaceProps {
  redirectURI?: string;
  reversed?: boolean;
  btnText?: string;
  additionalClassNames?: string;
}

export const GetInTouchBtn: React.FC<GetInTouchBtnInterfaceProps> = ({
  redirectURI = '/contact',
  reversed = false,
  btnText = 'Get in touch',
  additionalClassNames = '',
}: GetInTouchBtnInterfaceProps) => {
  const navigate: NavigateFunction = useNavigate();

  const handleGetInTouch = (): void => {
    navigate(redirectURI);
  };

  return (
    <Button
      className={`${reversed ? 'get-in-touch-btn-reversed' : 'get-in-touch-btn'} roboto-font-style d-flex justify-content-center ${additionalClassNames}`}
      onClick={handleGetInTouch}
    >
      {btnText} &nbsp;&nbsp;&nbsp;&nbsp;
      <FiArrowRight className="get-in-touch-btn-icon" />
    </Button>
  );
};

export default GetInTouchBtn;
