import { IProject, IProduct, IService } from './interfaces';
import DigipolisLogo from '../imgs/projects/digipolis-Antwerpen.png';
import WobbyLogo from '../imgs/projects/wobby_ai_logo.jpg';
import InfoSupportLogo from '../imgs/projects/info_support_logo.jpg';
import Ready4LifeLogo from '../imgs/projects/Ready_Four_Life.jpg';
import PhishedLogo from '../imgs/projects/phished_logo.jpg';
import BignitedLogo from '../imgs/projects/bignited.jpg';

export const projects: IProject[] = [
  {
    companyName: 'Digipolis Antwerp',
    companyLogo: DigipolisLogo,
    projectLabel: 'freelance',
    companyNameShort: 'Digipolis',
    companyDescription:
      'Digipolis Antwerpen serves as the IT partner for the City of Antwerp, overseeing a diverse range of government-related projects within the municipal group.',
    projectContribution: [
      'Implemented precise back-end code for high-priority features.',
      'Fixed bugs within a couple projects',
    ],
    projectPeriod: '10/2023 - Present',
    projectDuration: '',
    projectWebsite: 'https://www.digipolisantwerpen.be/',
  },
  {
    companyName: 'Wobby.ai',
    companyLogo: WobbyLogo,
    projectLabel: 'freelance',
    companyNameShort: 'Wobby',
    companyDescription:
      'Wobby is an AI-powered data analytics tool that makes it possible for everyone to interact with data very easily and human-friendly.',
    projectContribution: [
      'ElasticSearch dashboards for login and platfrom activity.',
      'Integrated new datasets into an ETL data flow.',
    ],
    projectPeriod: '05/2023 - 10/2023',
    projectDuration: '6 Months',
    projectWebsite: 'https://wobby.ai/',
  },
  {
    companyName: 'Phished',
    companyLogo: PhishedLogo,
    projectLabel: 'student',
    companyNameShort: 'Phished',
    companyDescription:
      'Phished is a cyber resilience training platform that goes beyond phishing simulations, automating awareness training to enhance employee behavior.',
    projectContribution: [
      'Successfully replicated 50+ websites with meticulous attention to detail.',
      'Composed 50+ compelling phishing emails by cloning corporate emails.',
    ],
    projectPeriod: '12/2022 - 06/2023',
    projectDuration: '7 Months',
    projectWebsite: 'https://www.phished.io',
  },
  {
    companyName: 'Info Support',
    companyLogo: InfoSupportLogo,
    projectLabel: 'student',
    companyNameShort: 'Info Support',
    companyDescription:
      'Info Support, an IT consulting firm, annually sponsors a project for a non-profit organization.',
    projectContribution: [
      'Implemented precise back-end code for high-priority features.',
      'Gained valuable experience by contributing to the front-end in React TS.',
    ],
    projectPeriod: '07/2022 - 08/2022',
    projectDuration: '2 Months',
    projectWebsite: 'http://www.infosupport.com/',
  },
  {
    companyName: 'Ready4Life',
    companyLogo: Ready4LifeLogo,
    projectLabel: 'internship',
    companyNameShort: 'Ready4Life',
    companyDescription:
      'Ready4Life Trust is a South African Non-Profit organization focused on improving education in disadvantaged communities.',
    projectContribution: [
      'Transformed the SQL database into user-friendly SharePoint tables.',
      'Migrated the MS Access application to an online PowerApps version.',
    ],
    projectPeriod: '04/2022 - 06/2022',
    projectDuration: '3 Months',
    projectWebsite: 'http://www.ready4life.org',
  },
  {
    companyName: 'b.ignited',
    companyLogo: BignitedLogo,
    projectLabel: 'student',
    companyNameShort: 'b.ignited',
    companyDescription:
      'b.ignited, part of the Cronos group, develops and integrates Test Automation Solutions, offering flexible and cost-effective solutions.',
    projectContribution: [
      'Produced a set of highly comprehensive installation guides.',
      'Engineered a Java/Spring Boot project, based on Swagger Petstore.',
    ],
    projectPeriod: '07/2021 - 08/2021',
    projectDuration: '2 Months',
    projectWebsite: 'http://www.bignited.be',
  },
];

export const products: IProduct[] = [
  {
    title: '(CMS) Platform Websites',
    mainDescription:
      'Are you familiar with WordPress, Shopify, Wix, or Drupal? If not, you should be. These platforms offer versatile solutions for a variety of needs, including:',
    advantages: [
      'Adaptability to most use cases',
      'Rapid development',
      'Highly flexible for changes/updates',
      'Cost-effective',
    ],
    subDescription:
      'With their user-friendly interfaces and extensive customization options, CMS-built websites offer a much more convenient solution for most use cases. This means faster development and reduced costs for your business.',
  },
  {
    title: 'Custom Built Websites',
    mainDescription:
      'In instances where off-the-shelf solutions fall short, custom-built websites excel in meeting exacting specifications.',
    advantages: [
      'Tailored for specific use cases',
      'Prolonged development',
      'Time-consuming yet flexible',
      'Endless possibilities',
    ],
    subDescription:
      'Custom websites often require more time for development due to the extensive setup involved compared to CMS websites.',
  },
  {
    title: 'Proof of concepts',
    mainDescription: 'Certain ideas benefit from a proof of concept to prototype their execution.',
    advantages: ['Idea validation', 'Rapid implementation', 'Identifies risks upfront', 'Early feedback loop'],
    subDescription:
      'Emphasizing rapid experimentation and exploration for concept validation. Prioritizing speed and comprehensive exploration enables quick iterations and adjustments, fostering innovation and agility in adapting to new insights and challenges.',
  },
];

export const services: IService[] = [
  {
    title: 'Full-Stack Development',
    mainDescription:
      'Experience comprehensive development encompassing both front-end and back-end technologies. Your project receives complete attention, ensuring a holistic solution from start to finish.',
  },
  {
    title: 'Back-End Development',
    mainDescription:
      'Experience the brains behind your project with our back-end development solutions. From database management to API integration, we handle the complexities, allowing your concept to thrive.',
  },
  {
    title: 'Front-End Development',
    mainDescription:
      'Transform your vision into reality with our front-end development prowess. Craft immersive user interfaces that not only look beautiful but also deliver seamless navigational and interactive experience.',
  },
];
