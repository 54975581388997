import React from 'react';

import { IService } from '../../../../models';
import GetInTouchBtn from '../../buttons/get-in-touch-btn/GetInTouchBtn';

import './ServiceCard.scss';

interface ServiceCardInterfaceProps {
  service: IService;
  index: string;
}

export const ServiceCard: React.FC<ServiceCardInterfaceProps> = ({ service, index }: ServiceCardInterfaceProps) => {
  return (
    <div key={index} className="card-service mx-auto">
      <h3 className="label-service">{service.title}</h3>
      <p className={`description font-weight-bold expanded roboto-font-style`}>{service.mainDescription}</p>
      <div className="d-flex justify-content-center">
        <GetInTouchBtn redirectURI={'/contact/' + service.title} reversed={true}></GetInTouchBtn>
      </div>
    </div>
  );
};
