import React from 'react';
import { Canvas } from 'react-three-fiber';
import Model from './logo-model/Model';
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FiLayers } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import background from '../../../imgs/pexels-dreamypixel-552784.jpg';
import software from '../../../imgs/software-icon.png';
import { GetInTouchBtn, HeaderNav } from '../../shared';

import './HomePage.scss';

export function HomePage() {
  const navigate = useNavigate();

  const handleWhatWeOffer = () => {
    navigate('/services');
  };

  return (
    <div>
      <h1 className="d-none">Home</h1>
      <HeaderNav currentPageName="Home" />
      <div className="home-container">
        <div className="background-image">
          <img src={background} alt="" />
        </div>
        <div
          className="m-auto"
          style={{
            maxWidth: '85rem',
            paddingTop: '1rem',
            paddingBottom: '10rem',
          }}
        >
          <div className="upper-pane">
            <Canvas className="m-auto " style={{ height: '15rem', maxWidth: '25rem' }}>
              <ambientLight intensity={0.5} />
              <directionalLight intensity={0.5} position={[2, 2, 2]} />
              <Model position={[0, 0, 1.25]} rotation={[1.5, 0, 0]} scale={[95, 95, 95]} />
            </Canvas>
            <div className="d-flex justify-content-center pt-1">
              <span className="tagline hard-font-style px-5">
                Where Innovation Meets Integrity: Shaping the Future of IT
              </span>
            </div>

            <div className="roboto-font-style">
              <div className="d-flex justify-content-center">
                <p className="text-under-tag-line text-center">
                  Multilingual tech enthusiast experienced in Java, C#, Python, React, and Elasticsearch. Proficient in
                  English, Dutch, and Russian, my journey includes diverse experiences and an international touch. Let's
                  collaborate to shape a better future.
                </p>
              </div>
              <Row className="d-flex justify-content-center py-3">
                <Col sm={6} className="my-2">
                  <GetInTouchBtn additionalClassNames={'ml-auto'}></GetInTouchBtn>
                </Col>
                <Col sm={6} className="my-2">
                  <Button className="what-we-offer-btn d-flex justify-content-center" onClick={handleWhatWeOffer}>
                    What we offer &nbsp;&nbsp;&nbsp;&nbsp;
                    <FiLayers className="what-we-offer-btn-icon" />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="m-auto" style={{ maxWidth: '85rem' }}>
        <div className="introduction-card">
          <h2 className="font-weight-bold hard-font-style text-center pb-3">
            Software is the fundament of the world economy
          </h2>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <img src={software} style={{ width: '12.5rem' }} className="img-fluid mx-auto" alt="" />
              </div>
              <div className="col-sm-12 col-md-6">
                <p className="pt-2">
                  In today's digital era, software plays a pivotal role in driving innovation and transforming
                  industries.
                  <br />
                  <br />
                  At <b> DS-Innovation</b>, we are dedicated to fostering the development of cutting-edge software
                  solutions, empowering businesses to thrive in a rapidly evolving technological landscape. Join us in
                  shaping the future of software and unlocking new opportunities for growth and advancement.
                </p>
                <GetInTouchBtn reversed={true} additionalClassNames={'mx-auto'}></GetInTouchBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
