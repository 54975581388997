import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { Footer } from './components/shared';
import { HomePage, AboutPage, ContactPage, ServicePage } from './components/pages';

import './index.scss';

const queryClient = new QueryClient();

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact/:redirectReason" element={<ContactPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/services" element={<ServicePage />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </ChakraProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}
