import React from 'react';

import { HeaderNav } from '../nav/HeaderNav';

import './MainWrapper.scss';

interface MainWrapperProps {
  currentPageName: string;
  headerPageText?: string;
  children: React.ReactNode;
}

export const MainWrapper: React.FC<MainWrapperProps> = ({
  currentPageName,
  children,
  headerPageText = currentPageName,
}: MainWrapperProps) => {
  return (
    <div>
      <h1 className="d-none">{currentPageName}</h1>
      <HeaderNav currentPageName={currentPageName} />
      <div className="m-auto page-max-width">
        <div className="main-wrapper">
          <h2 className="hard-font-style mx-3">{headerPageText}</h2>
          {children}
        </div>
      </div>
    </div>
  );
};
