import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { MainWrapper, ProjectCard } from '../../shared';
import { IProject, projects } from '../../../models';
import imgProfile from '../../../imgs/profiel-foto.jpg';

import './AboutPage.scss';

export function AboutPage() {
  return (
    <MainWrapper currentPageName="About" headerPageText="About us">
      <Row>
        <Col md={6}>
          <div className="d-flex justify-content-center">
            <img className="rounded-circle" width={'40%'} src={imgProfile} alt="" />
          </div>
        </Col>
        <Col md={6} className="roboto-font-style h3 font-weight-normal about-me-text">
          <p>Hi, I&apos;m Daniel, founder of DS. I specialize in full-stack development, crafting tailored websites.</p>

          <p>
            At DS, we excel in efficient solutions through collaboration—shaping interfaces and architecting robust
            back-end systems with a commitment to excellence.
          </p>

          <p>
            <b>Curious about our help?</b> DS collaborates with you to create custom websites tailored to your needs.
            We&apos;re not just here to provide solutions; we&apos;re dedicated to understanding and meeting your unique
            requirements.
          </p>
        </Col>
      </Row>
      <br />
      <br />
      <h2 className="hard-font-style">What projects have we worked on?</h2>
      <Row className="p-0 m-0">
        {projects.map((project: IProject, index: number) => (
          <ProjectCard key={index} project={project} />
        ))}
      </Row>
    </MainWrapper>
  );
}

export default AboutPage;
