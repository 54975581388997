import React from 'react';

import './Footer.scss';

export const Footer = () => {
  return (
    <div className="text-secondary small font-weight-bold">
      <hr />
      <p className="d-flex justify-content-center">{new Date().getFullYear()} DS-innovation BV</p>
      <p className="d-flex justify-content-center">BE 1001.488.871</p>
    </div>
  );
};
