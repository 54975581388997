import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { IProject } from '../../../../models';

import './ProjectCard.scss';

interface ProjectCardInterfaceProps {
  project: IProject;
}

export const ProjectCard: React.FC<ProjectCardInterfaceProps> = ({ project }: ProjectCardInterfaceProps) => {
  const [gradientDegree] = useState<number>(Math.floor(Math.random() * 360));

  const gradientStyle = {
    background: `linear-gradient(${gradientDegree}deg,#eeaeca, #94bbe9)`,
    backgroundSize: `400% 400%`,
    WebkitAnimation: `CardAnimation 10s ease infinite`,
    MozAnimation: `CardAnimation 10s ease infinite`,
    OAnimation: `CardAnimation 10s ease infinite`,
    animation: `CardAnimation 10s ease infinite`,
  };

  const calculateMonthsPassed: () => number = (): number => {
    // Set the target date (October 25, 2023)
    const targetDate = new Date('2023-10-25');

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in months
    return (
      (currentDate.getFullYear() - targetDate.getFullYear()) * 12 + currentDate.getMonth() - targetDate.getMonth() + 1
    );
  };

  return (
    <Col className="p-0 m-0 d-flex justify-content-center" sm={12} md={6} lg={6} xl={4}>
      <div className="p-3 m-2 card-project d-flex justify-content-center">
        <div className="">
          <div className="d-flex justify-content-center">
            <p className={`text-center label-project ${project.projectLabel} hard-font-style`}>
              {project.projectLabel}
            </p>
          </div>
          <a href={project.projectWebsite}>
            <Row>
              <Col sm={4} className="d-flex justify-content-center">
                <img src={project.companyLogo} className="img-project" alt={project.companyName + ' Logo'} />
              </Col>
              <Col className="d-flex justify-content-start align-items-center project-title">
                <h3>{project.companyName}</h3>
              </Col>
            </Row>
          </a>
          <div className="main-text-project mx-1 px-4" style={gradientStyle}>
            <div>
              <h4>About {project.companyNameShort}</h4>
              <p className="paragraph-project">{project.companyDescription}</p>
              <h4>Contribution</h4>
              <ul className="paragraph-project ul-project">
                {project.projectContribution.map((contribution: string, index: number) => (
                  <li key={index}>{contribution}</li>
                ))}
              </ul>
              <h4>Duration</h4>

              <p className="paragraph-project">
                {project.projectPeriod} <br />{' '}
                {project.projectDuration === '' ? calculateMonthsPassed() + ' Months' : project.projectDuration}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};
