import React, { useState } from 'react';
import { MdOutlineExpandMore } from 'react-icons/md';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import { IProduct } from '../../../../models';
import GetInTouchBtn from '../../buttons/get-in-touch-btn/GetInTouchBtn';

import './ProductCard.scss';

interface ProductCardInterfaceProps {
  product: IProduct;
  index: string;
}

export const ProductCard: React.FC<ProductCardInterfaceProps> = ({ product, index }: ProductCardInterfaceProps) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div key={index} className="card-product mx-auto">
      <button className="expand-button d-flex justify-content-center mb-3" onClick={(): void => setToggle(!toggle)}>
        <MdOutlineExpandMore size={36} className={`expand-icon ${toggle ? 'expanded' : ''}`} />
      </button>
      <h3 className="label-product">{product.title}</h3>
      <p className={`description font-weight-bold ${toggle ? 'expanded' : ''}`}>{product.mainDescription}</p>
      <div className="advantages-product pt-2">
        {product.advantages.map((advantage, internalIndex) => (
          <p className="d-flex font-weight-bold roboto-font-style" key={internalIndex}>
            <IoIosCheckmarkCircle size={30} className="advantage-circle"></IoIosCheckmarkCircle>
            {advantage}
          </p>
        ))}
      </div>
      <p className={`description ${toggle ? 'expanded' : ''}`}>{product.subDescription}</p>
      <div className="d-flex justify-content-center">
        <GetInTouchBtn redirectURI={'/contact/' + product.title} reversed={true}></GetInTouchBtn>
      </div>
    </div>
  );
};
